import React, { useState, useRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { useCookies } from "react-cookie";
import Layout from "utils/Layout";
import { Post, Password } from "components/BlogPostTemplate";
import { constContent } from "staticContent/blog";
import showModal from "services/popupModal";
import { dateOptions } from "constants/blogPostTemplate.constants";
import { getFormatedDate } from "helpers/blog.helpers";
import "styles/components/post.scss";

const { passwordInfo, btn, pageTitle, newsletterPageTitle, modal } =
  constContent;

let cookieExpireDate = new Date();
cookieExpireDate.setDate(cookieExpireDate.getDate() + 10);

const BlogPostTemplate = ({ data }) => {
  const articleTop = useRef();
  const [postPlaceholder, setPostPlaceholder] = useState("");
  const [cookies, setCookie] = useCookies(["unlockedPosts"]);
  const {
    body,
    frontmatter: { tags, date, postImgs, title, author },
  } = data.mdx;

  const showNewsletterPost = password => {
    if (password.toLowerCase() === "newsletter") {
      setCookie("unlockedPosts", "unlocked", { expires: cookieExpireDate });
      setPostPlaceholder("post");
    } else showModal(modal.title, modal.icon);
  };

  useEffect(() => {
    articleTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (tags.includes("Newsletter")) {
      setPostPlaceholder(cookies.unlockedPosts ? "post" : "password");
      return;
    }
    setPostPlaceholder("post");
  }, [tags, cookies.unlockedPosts]);

  const placeholder = {
    post: (
      <Post
        date={getFormatedDate(date, dateOptions.posts)}
        imgs={postImgs}
        title={title}
        author={author}
        body={body}
      />
    ),
    password: (
      <Password
        txtStart={passwordInfo.start}
        txtEnd={passwordInfo.end}
        txtBtn={btn.password}
        getPosts={showNewsletterPost}
      />
    ),
  };

  return (
    <Layout subTitle={title}>
      <section className="section hero blog blog--post">
        <Link to={tags.includes("Newsletter") ? "/newsletter" : "/blog"}>
          <h1 className="hero__banner-text">
            {tags.includes("Newsletter") ? newsletterPageTitle : pageTitle}
          </h1>
        </Link>
      </section>
      <div className="blog-wrapper blog-wrapper--post">
        <article className="blog__article blog__article--post" ref={articleTop}>
          {placeholder[postPlaceholder]}
        </article>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query MdxPostsById($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date
        author
        tags
        postImgs {
          childImageSharp {
            gatsbyImageData(layout: FIXED, formats: WEBP)
          }
        }
      }
    }
  }
`;
